// src/components/Form3.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPageChange } from '../WebSocketService';

const Form3 = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        sendPageChange('form4');
        navigate('/form4');
    };

    return (
        <div>
            <h1>Form 3</h1>
            <button onClick={handleNext}>Next</button>
        </div>
    );
};

export default Form3;
