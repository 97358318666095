import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPageChange } from '../WebSocketService';

const Form2 = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        sendPageChange('form3');
        navigate('/form3');
    };

    return (
        <div>
            <h1>Form 2</h1>
            <label>
                Name: <input type="text" />
            </label>
            <br />
            <label>
                Second Name: <input type="text" />
            </label>
            <br />
            <button onClick={handleNext}>Next</button>
        </div>
    );
};

export default Form2;
