
// src/QRCodeComponent.js
import React from 'react';
import QRCode from 'react-qr-code';

const QRCodeComponent = ({ syncUrl }) => (
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <QRCode value={syncUrl} size={128} />
    </div>
);

export default QRCodeComponent;

