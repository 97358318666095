// src/components/Form1.js
import React from 'react';

const Form1 = ({ onNext }) => (
    <div>
        <h2>Form 1</h2>
        <button onClick={onNext}>Next</button>
    </div>
);

export default Form1;
