
// export default QRScanner;
import React, { useState } from 'react';
import QrReader from 'react-qr-scanner';
import { sendOpenUrl } from '../WebSocketService';

const previewStyle = {
    height: 240,
    width: 320,
};

const QRScanner = ({ roomId }) => {
    const [result, setResult] = useState(null);

    const handleScan = (data) => {
        console.log("RCHINGGGGGG")
        if (data) {
            const scannedUrl = data.text;
            setResult(scannedUrl);
            sendOpenUrl(roomId, scannedUrl); // Send the URL to the server
            // window.location.href = scannedUrl; // Redirect on mobile itself
        }
    };

    const handleError = (err) => {
        // console.error(err);
    };

    return (
        <div>
            <h2>QR Scanner</h2>
            {/* <QrReader
                delay={300}
                style={previewStyle}
                onError={handleError}
                onScan={handleScan}
            /> */}
            {result && <p>Scanned URL: {result}</p>}
        </div>
    );
};

export default QRScanner;
